import {IDataGridMadel} from "../../model";
import {createGetProps} from "./helpers";
import {api, apiStatic, checkEs6AndRun, requestError} from "../../../helpers";
import {notifyRequestResult} from "../../../../store/modules/notify";

export const requestSwapPath = (url: string, method: 'patch' | 'put', data: any) => {
  return apiStatic.request({url: checkEs6AndRun(url, data), method, data})
};

export const requestSwap = (
  {
    model,
    sortable,
    filters,
    onTasks,
    dispatch,
    payload
  }: {
    model: IDataGridMadel,
    sortable: any;
    filters: any;
    onTasks: any;
    dispatch: any;
    payload: { data: any, take: number } & any
  }) => {
  // params get requests
  const params = createGetProps({model, pagination: {page: 1, size: 1}, filters, sortable});
  if (payload.take - 1) params.params.Skip = payload.take - 1;
  params.params.Take = 1;
  params.params.Select = `${model.store.mainField},${model.store.swap?.field}`;
  delete params.params.Count;
  // patch method
  onTasks([
    ['onHandle', {type: 'swapStart', params: {...params}, payload: {...payload}}],
    ['loading', true]
  ]);
  api
    .request({...params, method: 'get'})
    .then(response => {
      const data_ = response.data.value[0];
      const ID_ = model.store.mainField;
      const field_: any = model.store.swap?.field;
      Promise.all([
        // @ts-ignore
        requestSwapPath(model.store.patch?.url, model.store.patch?.method, {
          [ID_]: data_[ID_],
          [field_]: payload.data[field_]
        }),
        // @ts-ignore
        requestSwapPath(model.store.patch?.url, model.store.patch?.method, {
          [ID_]: payload.data[ID_],
          [field_]: data_[field_]
        })
      ])
        .then(_ => {
          dispatch({type: 'SOURCES_UPDATE', path: params.url});
          onTasks([
            ['onHandle', {type: 'swapDone', params: {...params}, payload: {...payload}}],
            ['getData'],
          ]);
        })
        .catch(error => {
          onTasks([
            ['onHandle', {type: 'getError', error: error}],
            ['loading', false]
          ]);
          dispatch(notifyRequestResult(requestError(error), 'error'));
          console.error(error);
        })
    })
    .catch(error => {
      onTasks([
        ['onHandle', {type: 'getError', error: error}],
        ['loading', false]
      ]);
      dispatch(notifyRequestResult(requestError(error), 'error'));
      console.error(error);
    })
};
