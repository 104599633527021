import {put, call, takeLatest} from 'redux-saga/effects'
import * as actions from "../modules/auth"
import {api, requestError} from "../../components/helpers"
import {notifyRequestResult} from "../modules/notify";

export const ERROR_AS_SUCCESS_GENERATE = 'already-generated';

function* errorHandler(error: any) {
  const errorMessage = requestError(error);
  yield put(notifyRequestResult(errorMessage, 'error'));
  return errorMessage
}

function* getUser(): any {
  yield put(actions.authStart());
  try {
    let response = yield call(api.get, 'Accounts/GetCurrentAppUser');
    yield put(actions.authSuccess(response.data));
  } catch (error) {
    // TODO: if Error Axios try update Token
    // yield put(actions.checkLoggedFail());
    return error;
  }
}

function* authUser(action: any): any {
  yield put(actions.authStart());
  try {
    const {cookie, ...data} = action.data;
    let response = yield call(api.post, 'Accounts/LoginWithCode', data);
    if (response.status === 200) {
      try {
        yield call(getUser);
      } catch (error) {
        const errorMessage = yield call(errorHandler, error);
        yield put(actions.authFail(errorMessage));
      }
    } else {
      const errorMessage = yield call(errorHandler, response);
      yield put(actions.authFail(errorMessage));
    }
  } catch (error) {
    const errorMessage = yield call(errorHandler, error);
    yield put(actions.authFail(errorMessage));
  }
}

// @ts-ignore
function* authCode(action: any): any {
  yield put(actions.authStart());
  try {
    yield call(api.post, 'Accounts/GeneratePassword', action.data);
    yield put(actions.authCodeSuccess());
  } catch (error) {
    if (requestError(error) === ERROR_AS_SUCCESS_GENERATE) {
      yield put(actions.authFail(requestError(error)));
    } else {
      const errorMessage = yield call(errorHandler, error);
      yield put(actions.authFail(errorMessage));
    }
  }
}

function* logout() {
  yield put(actions.authStart());
  try {
    yield call(api.post, 'Accounts/Logout');
    yield put(actions.logoutSucceed());
  } catch (error) {
    const errorMessage = requestError(error);
    yield put(notifyRequestResult(errorMessage, 'error'));
    yield put(actions.authFail(errorMessage));
  }
}

export default [
  takeLatest('AUTH_GET_USER', getUser),
  takeLatest('AUTH_USER', authUser),
  takeLatest('AUTH_CODE', authCode),
  takeLatest('AUTH_INITIATE_LOGOUT', logout),
];
