import {memo, useMemo} from "react";
import {KeyboardDateTimePicker, KeyboardDatePickerProps, KeyboardDatePicker} from "@material-ui/pickers";
import {isValid} from "date-fns";

export const DatePicker = memo((
  {
    value,
    error,
    format = 'dd/MM/yyyy HH:mm',
    showTodayButton = true,
    ampm = false,
    ...props
  }: {
    value?: any;
    error?: any | { message: string };
    format?: string;
    showTodayButton?: boolean;
    ampm?: boolean;
  } & KeyboardDatePickerProps & any
) => {
  const value_ = useMemo(() => {
    if (value) {
      if (typeof value === 'string' && isValid(new Date(value))) {
        return new Date(value);
      } else if (value instanceof Date) {
        return value;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }, [value]);
  const isTimeAvailable = useMemo(() => {
    return /[hHm]/g.test(format);
  }, [format]);
  return <div className="date-field-wrapper">
    {isTimeAvailable &&
    <KeyboardDateTimePicker
      {...props}
      error={Boolean(error)}
      helperText={error ? error.message || '' : ''}
      showTodayButton={showTodayButton}
      ampm={ampm}
      value={value_}
      format={format}
      onChange={(date: any) => {
        props.onChange({target: {value: date}});
      }}
      fullWidth
    />
    }
    {!isTimeAvailable &&
    <KeyboardDatePicker
      {...props}
      error={Boolean(error)}
      helperText={error ? error.message || '' : ''}
      showTodayButton={showTodayButton}
      value={value_}
      format={format}
      onChange={(date: any) => {
        props.onChange({target: {value: date}});
      }}
      fullWidth
    />
    }
  </div>

});

export default DatePicker;
