import clsx from "clsx"
import type { HTMLProps, Ref } from "react"
import { forwardRef, memo } from "react"
import style from "./index.module.scss"

export interface NativeScrollProps extends HTMLProps<HTMLDivElement> {
  className?: string
  mode?: "hover" | "visible"
}

const Component = (
  { className, children, mode = "visible", ...rest }: NativeScrollProps,
  ref: Ref<HTMLDivElement>,
) => {
  return (
    <div
      ref={ref}
      className={clsx(style.root, className, style[mode])}
      {...rest}
    >
      {children}
    </div>
  )
}

export const NativeScroll = memo(forwardRef(Component)) as typeof Component
