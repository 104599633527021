import { setCookies } from "AurionCR/components"

export const updateTokenAll = ({
  token = "",
  refreshToken = "",
  expires = "",
} = {}) => {
  setCookies(window.authCookieName_, token, expires)
  setCookies(window.authRefreshCookieName_, refreshToken, expires)
  setCookies(window.authDateCookieName_, expires, expires)
}
