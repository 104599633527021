import {FC, memo} from "react";
import {IDataGridColumnEditorNumber} from "../../../model";
import {Controller, useForm} from "react-hook-form";
import {TextField} from "@material-ui/core";
import {ClickAwayListener} from "@material-ui/core";
import Footer from "./footer";
import {validateRule} from "../../../../form/heplers";
import {useI18n} from "../../../../i18";

const EditorNumber: FC<{
  editor: IDataGridColumnEditorNumber,
  data: string,
  onClose: any,
  onSubmit: any
}> = ({editor, data, onClose, onSubmit}) => {
  const {t} = useI18n();
  const {handleSubmit, errors, control} = useForm({defaultValues: {data: data || 0}});
  return (
    <ClickAwayListener onClickAway={onClose}>
      <div className="grid-editor string">
        <form
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            as={
              <TextField
                fullWidth
                autoFocus
                type="number"
                error={Boolean(errors.data)}
                // @ts-ignore
                helperText={errors.data ? t(errors.data.message) : ''}
              />
            }
            name="data"
            control={control}
            rules={editor.required ? validateRule('required') : {}}
          />
          <Footer onClose={onClose}/>
        </form>
      </div>
    </ClickAwayListener>
  )
};

export default memo(EditorNumber)
