import { FC, memo, useCallback } from 'react';
import { IDataGridFilter } from '../../model';
import { InputAdornment } from '@material-ui/core';
import { debounce } from 'lodash';
import { useI18n } from '../../../i18';
import Input from '../../../form/input';
import SearchIcon from '@material-ui/icons/Search';

const FilterString: FC<{
  filter: IDataGridFilter;
  value: string;
  onTasks: any;
}> = ({ filter, value, onTasks }) => {
  const { t } = useI18n();
  // eslint-disable-next-line
  const debounceOnChange = useCallback(
    debounce((value: string) => {
      onTasks([['pagination', { page: 1 }], ['filters', { [filter.field]: value }], ['getData']]);
    }, 400),
    [],
  );
  return (
    <Input
      // @ts-ignore
      label={t(filter.options?.label)}
      placeholder={t(filter.options?.placeholder || 'search')}
      defaultValue={value || ''}
      style={filter.options?.style}
      onChange={(e: any) => debounceOnChange(e.target.value)}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default memo(FilterString);
