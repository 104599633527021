import {memo, useCallback, useMemo} from "react";
import {IDataGridFilter} from "../../model";
import {DatePicker} from '../../../form/date'
import {format, parse} from "date-fns";
import {isValid} from "date-fns/fp";
import {useI18n} from "../../../i18";

export default memo((
  {
    filter,
    value,
    onTasks
  }: {
    filter: IDataGridFilter & any,
    value: any,
    onTasks: any
  }
) => {
  const onChange = useCallback((value) => {
    if (value && isValid(value)) {
      onTasks([
        ['pagination', {page: 1}],
        ['filters', {[filter.field]: format(value, filter.options.valueFormat)}],
        ['getData']
      ])
    } else if (!value) {
      onTasks([
        ['pagination', {page: 1}],
        ['filters', {[filter.field]: ''}],
        ['getData']
      ])
    }
    // eslint-disable-next-line
  }, [filter]);
  const value_ = useMemo(() => {
    if (value) {
      return parse(value, filter.options.valueFormat, new Date());
    } else {
      return null;
    }
    // eslint-disable-next-line
  }, [value]);

  const {t} = useI18n();
  return <>
    <DatePicker
      {...filter.options.pickerOptions}
      label={t(filter.options?.label)}
      format={filter.options?.format}
      placeholder={filter.options?.placeholder}
      style={filter.options?.style}
      fullWidth={false}
      onChange={(e: any) => onChange(e.target.value)}
      value={value_}
    />
  </>
})
