import {memo, useCallback} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useI18n} from "../i18";
import Select from "../form/select";
import {setLanguage} from "../../store/modules/language";

export const LanguageNav = memo(() => {
  const {t} = useI18n();
  const dispatch = useDispatch();
  const value = useSelector((state: any) => state.language.languageID, shallowEqual);
  const onChange = useCallback((e, value:any) => {
    dispatch(setLanguage(e.target.value, value, value.direction));
    // eslint-disable-next-line
  }, []);
  return <Select
    settings={{
      source: 'Languages/GetAllDynamic?Select=id,title,direction&Filter=isActive==true&OrderBy=rank',
      label: t('language')
    }}
    disableClearable
    value={value}
    onChange={onChange}
  />;
});
export default LanguageNav;
