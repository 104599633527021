import {memo, useCallback, useMemo} from "react";
import {Controller} from "react-hook-form";
import {validateRule} from "./heplers";

export const FormController = memo((
  {
    onUpdate,
    rules,
    errors,
    ...rest
  }: any) => {
  const rules_ = useMemo(() => rules ? validateRule(rules) || rules : undefined, [rules]);
  const error_ = useMemo(() => errors ? errors[rest.name] : undefined, [errors, rest.name]);
  const onChange_ = useCallback(([e, value]) => {
    let value_ = '';
    if (
      e.target &&
      e.target.value !== undefined &&
      e.target.value !== null &&
      e.target.type !== 'checkbox'
    ) {
      value_ = e.target.value;
    } else if (
      value !== undefined
      && value !== null
    ) {
      value_ = value;
    }
    // if (onUpdate) onUpdate(rest.name, e.target.value, value);
    return value_;
    // eslint-disable-next-line
  }, [onUpdate]);
  return <Controller
    {...rest}
    rules={rules_}
    error={error_}
    onChange={onChange_}
  />
});

export default FormController;
