import type { FC } from 'react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { MainNav, UserNav } from 'AurionCR/components/navigation';
import { Drawer, IconButton } from '@material-ui/core';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import MenuIcon from '@material-ui/icons/Menu';
import { useAuth } from 'AurionCR/components/auth';
import { useI18n } from 'AurionCR/components/i18';
import IconsNav from './icons-nav';
import LogoCircle from 'images/logo-circle.svg?react';
import Logo from 'images/logo-main.svg?react';
import { debounce } from 'lodash';
import './_style.scss';
import { MenuLanguages } from './menu-languages';

const Layout: FC = (props) => {
  const auth = useAuth();
  const { t } = useI18n();
  const [open, setOpen] = React.useState(true);
  const [hover, setHover] = React.useState(false);
  const onToggle = useCallback(
    (bool: boolean) => {
      setOpen(bool);
      // @ts-ignore
      if (auth && auth.user)
        localStorage.setItem(`menu_user-${auth.user.appUserID}`, JSON.stringify({ open: bool }));
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 300);
    },
    [setOpen, auth],
  );
  const userNav = useMemo(() => {
    if (auth && auth.user && auth.permission('isAllowToEditProfile')) {
      return [{ title: 'profile', path: '/profile' }];
    } else {
      return [];
    }
  }, [auth]);
  // eslint-disable-next-line
  const onHover = useCallback(
    debounce((value: boolean) => {
      setHover(value);
    }, 300),
    [setHover],
  );

  useEffect(() => {
    if (auth && auth.user) {
      // @ts-ignore
      const store_ = JSON.parse(localStorage.getItem(`menu_user-${auth.user.appUserID}`) || '{}');
      setOpen(store_ && store_.open !== undefined ? store_.open : true);
    }
  }, [setOpen, auth]);
  return (
    <>
      <div className="root-content">
        {auth && auth.user && (
          <>
            <div className="root-left-sidebar">
              <div>
                <div className="menu-controls">
                  <IconButton onClick={() => onToggle(!open)}>
                    {open ? <MenuOpenIcon /> : <MenuIcon />}
                  </IconButton>
                </div>
                <UserNav menu={userNav} visibleTitle={false} />
                <IconsNav />
              </div>

              <MenuLanguages />
            </div>
            <Drawer
              className={`root-drawer drawer ${open ? 'opened' : ''} ${hover ? 'hover' : ''}`}
              variant="permanent"
              onMouseEnter={() => onHover(true)}
              onMouseLeave={() => onHover(false)}
            >
              <div className="logo">
                <Logo />
                <LogoCircle />
              </div>
              <UserNav
                menu={userNav}
                visibleAvatar={false}
                title={(data: any) => (
                  <>
                    <strong>{data.name}</strong>
                    <span>{data.userPosition.title}</span>
                  </>
                )}
              />
              <MainNav collapsed={!open && !hover} accordion={true} />
              <div className={`copyright ${open ? 'opened' : ''}`}>
                <div>{t('login-copyright')}</div>
              </div>
            </Drawer>
          </>
        )}
        <main className="root-page-content">{props.children}</main>
      </div>
    </>
  );
};

export default Layout;
