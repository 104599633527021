import type React from 'react';
import { useEffect } from 'react';
import { api, apiStatic } from 'AurionCR/components';
import type { InternalAxiosRequestConfig } from 'axios';
import axios from 'axios';
import { normalizeDataBeforeSave } from 'utils';

const instances = [apiStatic, api, axios];
const patch = (conf: InternalAxiosRequestConfig) => {
  if (conf.method === 'patch' && conf.data && typeof conf.data === 'object') {
    conf.data = Object.entries(normalizeDataBeforeSave(conf.data))
      .filter(([_, value]) => value !== undefined)
      .map(([path, value]) => {
        return { path, value };
      });
  }
  return conf;
};

export const AxiosInterceptorPatch: React.FC = ({ children }) => {
  useEffect(() => {
    instances.map((instance) => {
      return instance.interceptors.request.use(patch);
    });
  }, []);

  return <>{children}</>;
};
