import {memo, FC} from "react";
import {IDataGridToolbarControls} from "../../model";
import {Button} from "@material-ui/core";
import {useI18n} from "../../../i18";
import GetAppIcon from '@material-ui/icons/GetApp';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';

const Controls: FC<{
  controls: IDataGridToolbarControls,
  onTasks: (tasks: any[]) => void;
  toolBarControls: any;
  preview?: boolean;
}> = ({controls, onTasks, toolBarControls, preview}) => {
  const {t} = useI18n();
  if (!controls.refresh && !controls.new && !controls.export && !toolBarControls) return null;
  return (
    <ul className="toolbar-controls">
      {Boolean(toolBarControls) && toolBarControls()}
      {
        controls.refresh &&
        <li>
          <Button
            className="btn-refresh"
            title={controls.refresh}
            variant="contained"
            color="primary"
            size="small"
            onClick={_ => onTasks([['getData']])}
            startIcon={<RefreshIcon/>}
          >
            {t('refresh')}
          </Button>
        </li>
      }
      {
        controls.export &&
        <li>
          <Button
            className="btn-export"
            // @ts-ignore
            title={controls.export.title}
            variant="contained"
            color="primary"
            size="small"
            onClick={_ => onTasks([['getData']])}
            startIcon={<GetAppIcon/>}
          >
            {t('export')}
          </Button>
        </li>
      }
      {
        !preview && controls.new &&
        <li>
          <Button
            className="btn-new"
            // @ts-ignore
            title={controls.new.title}
            variant="contained"
            color="secondary"
            size="small"
            onClick={_ => onTasks([['onHandle', {type: 'new'}]])}
            startIcon={<AddIcon/>}
          >
            {t('new')}
          </Button>
        </li>
      }
    </ul>
  );
};

export default memo(Controls);
