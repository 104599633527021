import {FC, memo} from "react";
import {IDataGridMadel} from "../../model";
import Controls from "./controns";
import {useI18n} from "../../../i18";
import {Typography} from "@material-ui/core";

const Toolbar: FC<{
  model: IDataGridMadel,
  onTasks: any;
  toolBarControls: any;
  preview?: boolean
}> = ({model, onTasks, toolBarControls, preview}) => {
  const {t} = useI18n();

  if (!model || !model.toolbar || !model.toolbar.visible) return null;
  return <div className="toolbar-head">
    {
      model.toolbar.title &&
      <Typography variant="h4" className="title" color="secondary">{t(model.toolbar.title)}</Typography>
    }
    <Controls
      // @ts-ignore
      controls={model.toolbar.controls}
      onTasks={onTasks}
      toolBarControls={toolBarControls}
      preview={preview}
    />
  </div>
};

export default memo(Toolbar);
