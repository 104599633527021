import {memo, ReactNode, useEffect, useMemo, useRef, useState} from "react";
import {IDataGridMadel} from "../model";
import Head from "./head";
import Body from "./body";
import {NativeScroll} from "components/native-scroll";
import {throttle} from "lodash";

export default memo((
  {
    model,
    data,
    sortable,
    onTasks,
    rowControls,
    preview,
    pagination
  }
    : {
    model: IDataGridMadel;
    data: any[];
    sortable: any;
    onTasks: (tasks: any[]) => void;
    rowControls?: ReactNode;
    preview?: boolean;
    pagination: any;
  }
) => {
  const elScroll = useRef<HTMLDivElement>(null);
  const [scroll, setScroll] = useState({ x: 0, y: 0, '-x': 0 });

  const onScroll = useMemo(() => {
    return throttle(() => {
      const element = elScroll.current;

      if (!element) return;

      const x_ = Math.ceil(Math.abs(element.scrollLeft));
      const x__ = element.clientWidth - element.scrollWidth + x_;
      const y = element.scrollTop;
      const payload = { x: x_, '-x': x__, y };
      setScroll(payload);
    }, 300);
  }, []);

  useEffect(() => {
    const element = elScroll.current;

    if (!element) return;

    const resizeObserver = new ResizeObserver(() => {
      onScroll();
    });

    resizeObserver.observe(element);

    return () => {
      resizeObserver.unobserve(element);
    };
  }, [onScroll]);

  return <NativeScroll
    ref={elScroll}
    onScroll={onScroll}
    mode={'visible'}
    className={'data-grid-scroll'}
  >
    <table
      className={`${scroll.x ? 'is-scroll-x' : ''}${scroll.y ? ' is-scroll-y' : ''}${scroll['-x'] ? ' is-scroll--x' : ''}`}
    >
      <Head
        model={model}
        sortable={sortable}
        onTasks={onTasks}
        rowControls={rowControls}
        preview={preview}
      />
      <Body
        model={model}
        data={data}
        onTasks={onTasks}
        rowControls={rowControls}
        preview={preview}
        pagination={pagination}
      />
    </table>
  </NativeScroll>;
});
