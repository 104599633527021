import type React from "react"
import { useEffect, useState } from "react"
import { api, apiStatic, getCookies } from "AurionCR/components"

let isRefreshing = false
let refreshingCall: Promise<any>

interface Props {
  onRefresh: () => Promise<{
    token: string
    refreshToken: string
    expires: string
  }>
  onSuccess: (p: {
    token: string
    refreshToken: string
    expires: string
  }) => void
  onError: (e: Error) => void
}

export const AxiosInterceptorsRefreshToken: React.FC<Props> = ({
  children,
  onRefresh,
  onSuccess,
  onError,
}) => {
  const [canRender, setCanRender] = useState(false)

  useEffect(() => {
    setCanRender(true)
    const subscribes = [api, apiStatic].map(instance => {
      const id = instance.interceptors.response.use(
        response => response,
        error => {
          const status = error.response ? error.response.status : null

          const Token = getCookies(window.authCookieName_)
          const RefreshToken = getCookies(window.authRefreshCookieName_)

          if (status !== 401 || !(Token && RefreshToken)) {
            return Promise.reject(error)
          }

          if (isRefreshing) {
            return refreshingCall.then(() => {
              return api(error.config)
            })
          }
          isRefreshing = true
          refreshingCall = onRefresh()
            .then(res => {
              isRefreshing = false
              const { token, refreshToken, expires } = res
              onSuccess({ token, refreshToken, expires })
              return api(error.config)
            })
            .catch(err => {
              const errorStatus = err.response ? err.response.status : null
              isRefreshing = false
              if (errorStatus === 401) {
                onError(err)
              }
              return Promise.reject(err)
            })
          return refreshingCall
        },
      )
      return { id, instance }
    })

    return () => {
      subscribes.forEach(({ id, instance }) => {
        instance.interceptors.response.eject(id)
      })
    }
  }, [onRefresh, onSuccess, onError])

  return <>{canRender ? children : null}</>
}
