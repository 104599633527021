import type React from "react"
import { useEffect } from "react"
import { api, apiStatic, getCookies, requestError } from "AurionCR/components"
import axios from "axios"
import { updateTokenAll } from "utils"
import { useDispatch } from "react-redux"
import { authFail } from "AurionCR/store/modules/auth"

interface AxiosInterceptorsTokenProps {}

export const AxiosInterceptorsToken: React.FC<AxiosInterceptorsTokenProps> = ({
  children,
}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    const ids = [api].map(instance => {
      const id = instance.interceptors.response.use(
        response => {
          // update token
          const { method, url }: any = response.config
          if (method === "post") {
            if (url === "Accounts/LoginWithCode") {
              const {
                jwt: { token, refreshToken },
                expires,
              } = response.data
              updateTokenAll({ token, refreshToken, expires })
            } else if (url === "Accounts/Logout") {
              updateTokenAll()
            }
          }
          return response
        },
        error => {
          const {
            config: { url, method },
          } = error
          if (method === "get" && url === "Accounts/GetCurrentAppUser") {
            updateTokenAll()
            return dispatch(authFail(error ? requestError(error) : null))
          } else {
            throw error
          }
        },
      )
      return { id, instance }
    })

    return () => {
      ids.forEach(({ instance, id }) => {
        instance.interceptors.response.eject(id)
      })
    }
  }, [dispatch])

  useEffect(() => {
    const ids = [api, apiStatic, axios].map(instance => {
      const id = instance.interceptors.request.use(config => {
        const token = getCookies(window.authCookieName_ || "")
        if (token) {
          config.headers["Authorization"] = "Bearer " + token
        }
        return config
      })
      return { id, instance }
    })

    return () => {
      ids.forEach(({ instance, id }) => {
        instance.interceptors.request.eject(id)
      })
    }
  }, [])

  return <>{children}</>
}
