import { lazy } from "react"
import type { IRoute } from "AurionCR/components/router"
import DashboardIcon from "@material-ui/icons/Dashboard"
import ControlCameraIcon from "@material-ui/icons/ControlCamera"
import CategoryIcon from "@material-ui/icons/Category"
import TimelineIcon from "@material-ui/icons/Timeline"
import AnnouncementIcon from "@material-ui/icons/Announcement"
import SettingsIcon from "@material-ui/icons/Settings"
import PetsIcon from "@material-ui/icons/Pets"
import { PERMISSIONS } from "../components/models"

const routes: IRoute[] = [
  {
    path: "/",
    title: "dashboard",
    icon: DashboardIcon,
    component: lazy(
      () => import(/* webpackChunkName: 'Dashboard' */ "../pages/Dashboard"),
    ),
  },
  {
    path: "/rated-entities",
    title: "rated-entities",
    icon: TimelineIcon,
    permission: PERMISSIONS.IS_ALLOW_TO_EDIT_COMPANIES,
    routes: [
      {
        path: "/companies",
        title: "companies",
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'RatedEntities' */ "../pages/RatedEntities"
            ),
        ),
      },
      {
        path: "/sectors",
        title: "sectors",
        permission: PERMISSIONS.IS_ALLOW_TO_EDIT_METHODOLOGIES,
        component: lazy(
          () => import(/* webpackChunkName: 'Sectors' */ "../pages/Sectors"),
        ),
      },
      {
        path: "/categories",
        title: "categories",
        permission: PERMISSIONS.IS_ALLOW_TO_EDIT_METHODOLOGIES,
        component: lazy(
          () =>
            import(/* webpackChunkName: 'Categories' */ "../pages/Categories"),
        ),
      },
      {
        path: "/analysts",
        title: "analysts",
        component: lazy(
          () => import(/* webpackChunkName: 'Analysts' */ "../pages/Analysts"),
        ),
      },
    ],
  },
  {
    path: "/methodologies",
    title: "methodologies",
    icon: ControlCameraIcon,
    permission: PERMISSIONS.IS_ALLOW_TO_EDIT_METHODOLOGIES,
    routes: [
      {
        path: "/methodologies",
        title: "methodologies",
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'Methodologies' */ "../pages/Methodologies"
            ),
        ),
      },
      {
        path: "/series",
        title: "series",
        component: lazy(
          () => import(/* webpackChunkName: 'Series' */ "../pages/Series"),
        ),
      },
      {
        path: "/sectors",
        title: "sectors",
        component: lazy(
          () => import(/* webpackChunkName: 'Sectors' */ "../pages/Sectors"),
        ),
      },
      {
        path: "/categories",
        title: "categories",
        component: lazy(
          () =>
            import(/* webpackChunkName: 'Categories' */ "../pages/Categories"),
        ),
      },
      {
        path: "/outlooks",
        title: "outlooks",
        component: lazy(
          () => import(/* webpackChunkName: 'Outlooks' */ "../pages/Outlooks"),
        ),
      },
      {
        path: "/ratings",
        title: "ratings",
        component: lazy(
          () => import(/* webpackChunkName: 'Ratings' */ "../pages/Ratings"),
        ),
      },
      {
        path: "/rating-symbols",
        title: "rating-symbols",
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'RatingSymbols' */ "../pages/RatingSymbols"
            ),
        ),
      },
      {
        path: "/report-titles",
        title: "report-titles",
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'RatingSymbols' */ "../pages/ReportTitles"
            ),
        ),
      },
      // TODO remove after check the task
      {
        path: "/company-ratings",
        title: "company-ratings",
        inMenu: false,
        component: lazy(
          () =>
            import(/* webpackChunkName: 'Report' */ "../pages/CompanyRatings"),
        ),
      },
      // TODO remove after check the task
      {
        path: "/company-reports",
        title: "company-reports",
        inMenu: false,
        component: lazy(
          () =>
            import(/* webpackChunkName: 'Report' */ "../pages/CompanyReports"),
        ),
      },
    ],
  },
  {
    path: "/fci",
    title: "fci",
    icon: PetsIcon,
    permission: PERMISSIONS.IS_ALLOW_TO_EDIT_SETTING,
    routes: [
      {
        path: "/reports",
        title: "fci-reports",
        component: lazy(
          () => import(/* webpackChunkName: 'FciReports' */ "pages/FciReports"),
        ),
      },
      // {
      //   path: '/scores',
      //   title: 'fci-scores',
      //   component: lazy(() => import(/* webpackChunkName: 'FciScores' */ "pages/FciScores")),
      // }
    ],
  },
  {
    path: "/about",
    title: "about",
    icon: CategoryIcon,
    permission: PERMISSIONS.IS_ALLOW_TO_EDIT_CONTENT,
    routes: [
      {
        path: "/about-pages",
        title: "about-pages",
        component: lazy(
          () =>
            import(/* webpackChunkName: 'AboutPages' */ "../pages/AboutPages"),
        ),
      },
      {
        path: "/our-team",
        title: "our-team",
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'TeamMembersOur' */ "../pages/TeamMembersOur"
            ),
        ),
      },
      {
        path: "/board-of-directors",
        title: "board-of-directors",
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'TeamMembersDirectors' */ "../pages/TeamMembersDirectors"
            ),
        ),
      },
      {
        path: "/jobs",
        title: "jobs",
        component: lazy(
          () => import(/* webpackChunkName: 'Jobs' */ "../pages/Jobs"),
        ),
      },
      {
        path: "/conferences",
        title: "conferences",
        permission: PERMISSIONS.IS_ALLOW_TO_EDIT_MARKETING,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'Conferences' */ "../pages/Conferences"
            ),
        ),
      },
    ],
  },
  {
    path: "/content",
    title: "content",
    icon: AnnouncementIcon,
    permission: PERMISSIONS.IS_ALLOW_TO_EDIT_CONTENT,
    routes: [
      {
        path: "/articles",
        title: "articles",
        component: lazy(
          () => import(/* webpackChunkName: 'Articles' */ "../pages/Articles"),
        ),
      },
      {
        path: "/landing-pages",
        title: "landing-pages",
        permission: PERMISSIONS.IS_ALLOW_TO_EDIT_MARKETING,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'LandingPages' */ "../pages/LandingPages"
            ),
        ),
      },
      {
        path: "/home",
        title: "home",
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'CommonPages' */ "../pages/CommonPages/home"
            ),
        ),
      },
      {
        path: "/contact",
        title: "contact",
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'CommonPages' */ "../pages/CommonPages/contact"
            ),
        ),
      },
      {
        path: "/contact-thanks",
        title: "contact-thanks",
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'CommonPages' */ "../pages/CommonPages/contact-thanks"
            ),
        ),
      },
      {
        path: "/disclaimer",
        title: "disclaimer",
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'CommonPages' */ "../pages/CommonPages/disclaimer"
            ),
        ),
      },
      {
        path: "/accessibility",
        title: "accessibility",
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'CommonPages' */ "../pages/CommonPages/accessibility"
            ),
        ),
      },
      {
        path: "/public-information",
        title: "public-information",
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'PublicInformation' */ "../pages/PublicInformation"
            ),
        ),
      },
      {
        path: "/articles-layout",
        title: "articles-layout",
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'CommonPages' */ "../pages/CommonPages/articles-layout"
            ),
        ),
      },
      {
        path: "/methodologies-layout",
        title: "methodologies-layout",
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'CommonPages' */ "../pages/CommonPages/methodologies-layout"
            ),
        ),
      },
      {
        path: "/jobs-layout",
        title: "jobs-layout",
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'CommonPages' */ "../pages/CommonPages/jobs-layout"
            ),
        ),
      },
      {
        path: "/ratings-layout",
        title: "ratings-layout",
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'CommonPages' */ "../pages/CommonPages/ratings-layout"
            ),
        ),
      },
      {
        path: "/reports-layout",
        title: "reports-layout",
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'CommonPages' */ "../pages/CommonPages/reports-layout"
            ),
        ),
      },
      {
        path: "/fci",
        title: "fci",
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'CommonPages' */ "../pages/CommonPages/fci"
            ),
        ),
      },
    ],
  },
  {
    path: "/settings",
    title: "settings",
    icon: SettingsIcon,
    permission: PERMISSIONS.IS_ALLOW_TO_EDIT_SETTING,
    routes: [
      {
        path: "/users",
        title: "users",
        permission: PERMISSIONS.IS_ALLOW_TO_EDIT_PERMISSION,
        component: lazy(
          () => import(/* webpackChunkName: 'Users' */ "../pages/Users"),
        ),
      },
      {
        path: "/permissions",
        title: "permissions",
        permission: PERMISSIONS.IS_ALLOW_TO_EDIT_PERMISSION,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'Permissions' */ "../pages/Permissions"
            ),
        ),
      },
      {
        path: "/newsletter-contacts",
        title: "newsletter-contacts",
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'Permissions' */ "../pages/Newsletters"
            ),
        ),
      },
      {
        path: "/site-parameters",
        title: "site-parameters",
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'SiteParameters' */ "../pages/SiteParameters"
            ),
        ),
      },
      {
        path: "/external-channels",
        title: "external-channels",
        permission: PERMISSIONS.IS_ALLOW_TO_EDIT_PERMISSION,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'ExternalChannels' */ "../pages/ExternalChannels"
            ),
        ),
      },
      {
        path: "/languages",
        title: "languages",
        component: lazy(
          () =>
            import(/* webpackChunkName: 'languages' */ "../pages/Languages"),
        ),
      },
      {
        path: "/labels-cms",
        title: "labels-cms",
        component: lazy(
          () => import(/* webpackChunkName: 'Labels' */ "../pages/Labels/cms"),
        ),
      },
      {
        path: "/labels-website",
        title: "labels-website",
        component: lazy(
          () =>
            import(/* webpackChunkName: 'Labels' */ "../pages/Labels/website"),
        ),
      },
      {
        path: "/leads",
        title: "leads",
        permission: PERMISSIONS.IS_ALLOW_TO_EDIT_MARKETING,
        component: lazy(
          () => import(/* webpackChunkName: 'Leads' */ "../pages/Leads"),
        ),
      },
      {
        path: "/activity-log",
        title: "user-activity-log",
        permission: PERMISSIONS.IS_ALLOW_TO_EDIT_PERMISSION,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'UserActivityLog' */ "../pages/UserActivityLog"
            ),
        ),
      },
      {
        path: "/contact-subjects",
        title: "contact-subjects",
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'ContactSubjects' */ "../pages/ContactSubjects"
            ),
        ),
      },
    ],
  },
  {
    path: "/profile",
    title: "profile",
    inMenu: false,
    component: lazy(
      () => import(/* webpackChunkName: 'Profile' */ "../pages/Profile"),
    ),
  },
  {
    path: "/login",
    title: "login",
    inMenu: false,
    private: false,
    inLayout: false,
    component: lazy(
      () => import(/* webpackChunkName: 'Login' */ "../pages/Login"),
    ),
  },
]

export default routes
