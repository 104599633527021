import { format, parse } from 'date-fns';
import axios from 'axios';
import { validateURL } from './form';

export const baseURL = `${window.API_URL}/api/`;
/**
 * api settings
 */
export const api = axios.create({ baseURL: baseURL });
export const apiStatic = axios.create({ baseURL: baseURL });

// cookie
export const getCookies = (name: string) => {
  if (!name) return undefined;
  const matches = document.cookie.match(
    new RegExp(
      // eslint-disable-next-line
      '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)',
    ),
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};
export const setCookies = (name: string, data = '', expires = '', domain = '') => {
  if (data) {
    document.cookie = `${name}=${encodeURIComponent(data)};${expires ? ` expires=${new Date(expires).toUTCString()};` : ''} secure; domain=${domain ? domain : window.location.hostname}; samesite=lax; path=/;`;
  } else {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; secure; domain=${domain ? domain : window.location.hostname}; samesite=lax; path=/;`;
  }
};
/**
 * randoms
 */
export const getNewKey = () => new Date().getTime() + Math.random();
const dec2hex = (dec: number) => {
  return dec.toString(16).padStart(2, '0');
};
export const getRandomString = (length = 10) => {
  const arr = new Uint8Array((length || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join('');
};
/**
 * parsers requests handlers
 */
export const requestError = (error: any, defaultMessage = 'error') => {
  let message;
  if (error) {
    if (error.response) {
      if (typeof error.response === 'object') {
        if (typeof error.response.data === 'object') {
          message = error.response.data.message || undefined;
        } else if (typeof error.response.data === 'string') {
          message = error.response.data;
        }
      } else if (typeof error.response === 'string') {
        message = error.response;
      }
    }
  }
  return message || defaultMessage;
};
export const requestSuccess = (response: any, defaultMessage = 'success') => {
  let message;
  if (response) {
    if (typeof response === 'object') {
      if (typeof response.data === 'object') {
        message = response.data.message || undefined;
      } else if (typeof response.data === 'string') {
        message = response.data;
      }
    } else if (typeof response === 'string') {
      message = response;
    }
  }
  return message || defaultMessage;
};
/**
 * es6 templates
 */
export const es6Run = (data: any, field: string) => {
  return data[field] !== undefined ? data[field] : checkEs6AndRun(field, data);
};
export const checkES6Template = (value: any) => {
  return typeof value === 'string' && value.indexOf('${') !== -1;
};
export const checkEs6AndRun = (val: string | object, data: any = null) => {
  if (checkES6Template(val)) {
    // eslint-disable-next-line
    let func = new Function('data', `return \`${val}\``);
    return func(data);
  } else if (typeof val === 'function') {
    return val(data);
  } else {
    return val;
  }
};
export const parseJsTemplate = (object: any) => {
  for (let key in object) {
    if (object[key]) {
      let val = object[key];
      if (typeof val === 'string' && object[key].indexOf('${') !== -1) {
        // eslint-disable-next-line
        object[key] = new Function('data', `return \`${object[key]}\``);
      } else if (typeof object[key] === 'object' && Object.keys(object[key]).length) {
        parseJsTemplate(object[key]);
      }
    }
  }
  return object;
};
export const regExpFieldAS = new RegExp('(\\sas\\s)(.+)', 'g');
export const convertDateToRDateTime = (date: Date | undefined | null) => {
  if (date) {
    return format(date, 'yyyy,MM,dd,HH,mm,ss');
  } else {
    return date;
  }
};
export const convertRDateTimeToDate = (date: string | undefined | null) => {
  if (date) {
    return parse(date, 'yyyy,MM,dd,HH,mm,ss', new Date());
  } else {
    return date;
  }
};
// images
export const getImageSrc = (src: any) => {
  if (src && validateURL(src) && src.indexOf('data:image/') === -1) {
    return `data:image/png;base64,${src}`;
  }
  return src;
};
// Youtube code

export interface ITranslateBySource {
  map: any;
  loading: boolean;
  key: string;
}

export const translateBySource = ({ map, loading, key }: ITranslateBySource) => {
  if (loading) return ' ';
  const translate = map[key];
  if (!translate) {
    console.warn('label', key, 'not found');
    return key;
  }
  return translate.title;
};
