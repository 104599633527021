import type React from "react"
import { useCallback, useEffect } from "react"
import { AxiosInterceptorsToken } from "./token"
import { AxiosInterceptorsRefreshToken } from "./refresh-token"
import { api, baseURL, getCookies } from "AurionCR/components"

import { AxiosInterceptorsSources } from "./sources"
import { updateTokenAll } from "utils"
import { checkLogged } from "AurionCR/store/modules/auth"
import { useDispatch } from "react-redux"
import { AxiosInterceptorPost } from "contexts/axios-interceptors/post"
import { AxiosInterceptorPatch } from "contexts/axios-interceptors/patch"

const refresh = async (data: { token: string; refreshToken: string }) => {
  const response = await api.post("Accounts/RefreshToken", data)
  const {
    data: { token },
  } = response

  return fetch(`${baseURL}Accounts/GetCurrentAppUser`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  }).then(res => {
    if (!res.ok) {
      throw new Error(res.statusText)
    }
    return response
  })
}

export const AxiosInterceptors: React.FC = ({ children }) => {
  const onRefresh = useCallback(async () => {
    const token = getCookies(window.authCookieName_)
    const refreshToken = getCookies(window.authRefreshCookieName_)
    const expires = getCookies(window.authDateCookieName_) as string

    if (!token || !refreshToken) {
      throw new Error("token-not-exist")
    }

    const {
      data: { refreshToken: RefreshToken, token: Token },
    } = await refresh({ token, refreshToken })

    return { token: Token, refreshToken: RefreshToken, expires }
  }, [])

  const onError = useCallback(() => {
    if (window.location.pathname !== "/login") {
      window.location.href = `/login?redirect=${window.location.pathname}${window.location.search}`
    }
  }, [])

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(checkLogged())
  }, [dispatch])

  return (
    <AxiosInterceptorsToken>
      <AxiosInterceptorsRefreshToken
        onRefresh={onRefresh}
        onSuccess={updateTokenAll}
        onError={onError}
      >
        <AxiosInterceptorsSources>
          <AxiosInterceptorPost>
            <AxiosInterceptorPatch>{children}</AxiosInterceptorPatch>
          </AxiosInterceptorPost>
        </AxiosInterceptorsSources>
      </AxiosInterceptorsRefreshToken>
    </AxiosInterceptorsToken>
  )
}
