import { memo, useCallback } from 'react';
import { MenuItem, MenuList } from '@material-ui/core';
import style from './index.module.scss';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setLanguage } from 'AurionCR/store/modules/language.ts';
import { Source } from 'AurionCR/components';

type Language = {
  id: string;
  title: string;
  direction: number;
  culture: string;
};

export const MenuLanguages = memo(() => {
  const dispatch = useDispatch();

  const languageID = useSelector((state: any) => state.language.languageID, shallowEqual);
  const sourceLanguages = Source(
    'Languages/GetAllDynamic?Select=id,title,culture,direction&Filter=isActive==true&OrderBy=rank',
    'id',
    true,
  );

  const factoryChangeLanguage = useCallback(
    (lang: Language) => {
      return () => dispatch(setLanguage(lang.id as string, lang, lang.direction));
    },
    [dispatch],
  );
  return (
    <MenuList className={style.root}>
      {sourceLanguages.data.map((language: Language) => {
        return (
          <MenuItem
            key={language.id}
            selected={language.id === languageID}
            className={style.item}
            onClick={factoryChangeLanguage(language)}
            title={language.title || ''}
          >
            {language.culture}
          </MenuItem>
        );
      })}
    </MenuList>
  );
});
