import React, {memo, useCallback, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, Popover} from "@material-ui/core";
import {logout} from "../../store/modules/auth";
import AccountCircle from '@material-ui/icons/AccountCircle';
import useRouter from "../router";
import {useI18n} from "../i18";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

export const UserNav = memo((
  {
    title = (data: any) => <>
      <strong>{data.name}</strong>
      <span>{data.licenseTitle}</span>
    </>,
    menu = [{title: 'profile', path: '/settings/profile'}],
    fieldImagePath = 'thumbnailPicture',
    collapsed = false,
    visibleAvatar = true,
    visibleTitle = true,
    visibleArrow = false
  }: {
    title?: (data: any) => any;
    menu?: { title: string, path: string }[];
    fieldImagePath?: string;
    collapsed?: boolean;
    visibleAvatar?: boolean;
    visibleTitle?: boolean;
    visibleArrow?: boolean;
  }) => {
  const {t} = useI18n();
  const router = useRouter();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const user = useSelector(state => (state as any).auth.user);
  const avatar = useMemo(() => {
    if (visibleAvatar) {
      if (user && user[fieldImagePath]) {
        return <div
          className="avatar"
          style={{backgroundImage: `url(${user[fieldImagePath]})`}}
        />
      } else {
        return <AccountCircle/>
      }
    } else {
      return null;
    }
    // eslint-disable-next-line
  }, [user]);
  // handlers
  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);
  if (!user) return (<></>);
  return <div
    className={`user-nav${collapsed ? ' collapsed' : ''}${!visibleAvatar ? ' hide-avatar' : ''}${!visibleTitle ? ' hide-title' : ''}`}>
    <Button
      className="user-button"
      fullWidth
      size="large"
      onClick={e => setAnchorEl(e.currentTarget)}
      startIcon={avatar}
      endIcon={visibleArrow ? <ArrowDropDownIcon/> : null}
    >
      {visibleTitle && <div className="user-button-title">{title(user)}</div>}
    </Button>
    <Popover
      className="user-nav_drop-down"
      anchorEl={anchorEl}
      anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
      transformOrigin={{vertical: 'top', horizontal: 'right'}}
      onClose={onClose}
      open={Boolean(anchorEl)}
    >
      <div className="user-nav_drop-down-content">
        {menu && menu.length !== 0 && menu.map((val, i) =>
          <Button
            key={i}
            onClick={() => {
              router.push({pathname: val.path});
              onClose();
            }}>
            {t(val.title)}
          </Button>
        )}
        <Button
          onClick={() => {
            dispatch(logout());
            onClose();
          }}>
          {t('logout')}
        </Button>
      </div>
    </Popover>
  </div>;
});
export default UserNav;
