import {memo, useCallback, useEffect, useMemo, useState} from "react";
import Select, {ISelect} from "./select";
import {api, requestError} from "../helpers";
import {notifyRequestResult} from "../../store/modules/notify";
import {useDispatch} from "react-redux";
import {merge} from "lodash";

export interface ISelectMixinSettings {
  values: {
    source: string | any[]; // 'ArticleCategories'
    filter: {
      key?: string; // need to create and filter items by main form data, articleID == "form.data[filter.value]
      keyFromResponse?: string; // this field get after main PATCH/CREATE form data and add to post item
                                // [filter.key] = form.data[filter.keyFromResponse]
      value: string | number | null | undefined // see [filter.key]
    };
    select: {
      key?: string; // main field source, default = 'id'
      link: string; // this field need to connect main select source and this source
    }
  };
}

export const SelectMixin = memo(({values, ...rest}: any & ISelect & ISelectMixinSettings) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [source, setSource] = useState<{ values: any[]; map: any; }>({values: [], map: {}});
  // parsers
  const values_ = useMemo(() => {
    return merge({filter: {key: 'id', keyFromResponse: 'id'}, select: {key: 'id'}}, values)
    // eslint-disable-next-line
  }, []);
  // handlers
  const onChange = useCallback((values, source?) => {
    if (rest.onChange) {
      const selectValue = [...values];
      rest.onChange({
        target: {
          value: {
            mixin_: [
              ...selectValue.reduce((result: any, link: string | number) => {
                if (!source.map[link]) result.push({
                  method: 'post',
                  url: `${values_.source}/Create`,
                  data: {[values_.select.link]: link}
                });
                return result
              }, []),
              ...source.values.reduce((result: any, val: string | number) => {
                if (!selectValue.filter(item => item === val)[0]) result.push({
                  method: 'delete',
                  url: `${values_.source}/Delete/${source.map[val]}`
                });
                return result
              }, [])
            ],
            type_: 'selectMixin',
            mainFiled_: values_.filter.key,
            keyFromResponse_: values_.filter.keyFromResponse,
            source_: typeof values_.source === 'string' ? values_.source : '',
            value: selectValue
          }
        }
      });
    }
    // eslint-disable-next-line
  }, [rest.onChange]);
  // init
  useEffect(() => {
    if (values_.source && typeof values_.source === 'string' && values_.filter.value) {
      api
        .get(values_.source + '/GetAllDynamic', {
          params: {
            'Select': values_.select.key + ',' + values_.select.link,
            'Filter': `${values_.filter.key} == ${isNaN(Number(values_.filter.value)) ? `"${values_.filter.value}"` : values_.filter.value}`
          }
        })
        .then(response => {
          const source_ = response.data.value.reduce((result: any, item: any) => {
            result.values.push(item[values_.select.link]);
            result.map[item[values_.select.link]] = item[values_.select.key];
            return result
          }, {values: [], map: {}});
          setSource(source_);
          setLoading(false);
          onChange(source_.values, source_);
        })
        .catch(error => dispatch(notifyRequestResult(requestError(error), 'error')))
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [values_, setLoading, setSource]);
  // render
  return <Select
    className="mixin-select"
    {...rest}
    multiple
    value={source.values}
    onChange={(e: any) => onChange(e.target.value, source)}
    loading={loading}
  />
});

export default SelectMixin;
