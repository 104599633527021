import {FC, memo} from "react";
import ReactPaginate from "react-paginate";
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

const Pager: FC<{
  pagination: any,
  onTasks: any
}> = ({pagination, onTasks}) => {
  if (!pagination || isNaN(pagination.total) || pagination.total < 2) return null;
  return <ReactPaginate
    forcePage={pagination.page - 1}
    previousLabel={<KeyboardArrowLeftIcon/>}
    nextLabel={<KeyboardArrowRightIcon/>}
    breakLabel={'...'}
    breakClassName={'break-me'}
    pageCount={pagination.total}
    marginPagesDisplayed={1}
    pageRangeDisplayed={4}
    disableInitialCallback={true}
    onPageChange={(e) => onTasks([['pagination', {page: e.selected + 1}], ['getData']])}
    containerClassName={'pagination'}
    activeClassName={'active'}
  />
};

export default memo(Pager);
