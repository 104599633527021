import { memo, useMemo, useState} from "react";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import Source from "../source";
import {Loading} from "../loading";
import {useI18n} from "../i18";
import {format, isValid} from "date-fns";
import {ImageTooltip} from "../image-tooltip";
import {IconButton} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import LinkIcon from "@material-ui/icons/Link";
import CopyPathToClipboard from "../copy-path-to-clipboard";
import {useAuth} from "../auth";

export const PreviewSource = memo((
  {
    value,
    label
  }:
    {
      value: { value: string | number | undefined, source: string, fieldID: string, fieldTitle: string };
      label?: string;
    }
) => {
  const {t} = useI18n();
  const source = Source(value.source);
  const ID_ = useMemo(() => value.fieldID || 'id', [value.fieldID]);
  const TITLE_ = useMemo(() => value.fieldTitle || 'title', [value.fieldTitle]);
  const value_ = useMemo(() => {
    if (source.loading) {
      return t('loading');
    } else {
      // eslint-disable-next-line
      const item_ = source.data.filter((item: any) => item[ID_] == value.value)[0];
      if (item_) {
        return item_[TITLE_];
      } else {
        return t('not found item');
      }
    }
    // eslint-disable-next-line
  }, [source, value]);
  return <div className="preview-item source">
    <div className="label">{label}</div>
    <div className="value">{value_}</div>
    <Loading active={source.loading}/>
  </div>;
});

export const Value = memo(({isSecret, value, children}: any) => {
  const auth = useAuth();
  const [show, setShow] = useState(false);
  const isControls = useMemo(() => {
    if (typeof isSecret === 'string') return auth.permission(isSecret);
    return true;
    // eslint-disable-next-line
  }, [auth])
  if (!isSecret) return <div className="value">{children}</div>;
  return <div className="preview-secret-value">
    <div className="value">
      {show && children}
      {!show && Boolean(value) && value.replace(/./g, '*')}
    </div>
    {isControls && <>
      <IconButton
        size="small"
        onClick={() => setShow(!show)}
      >
        {!show ? <Visibility/> : <VisibilityOff/>}
      </IconButton>
      <CopyPathToClipboard text={value} message="copy-to-clipboard ">
        <IconButton size="small">
          <LinkIcon/>
        </IconButton>
      </CopyPathToClipboard>
    </>}
  </div>;
});

export const Preview = memo((
  {
    value,
    label,
    type = 'default',
    dateFormat = 'dd/MM/yyyy',
    isSecret = false,
    className = ''
  }: {
    value?: { value: string | number, active: boolean } | string | number | boolean | any;
    label?: string;
    type?: 'default' | 'main' | 'checkbox' | 'source' | 'date' | 'image' | 'email' | 'link' | 'html';
    dateFormat?: string;
    isSecret?: boolean | string;
    className?: string;
  }) => {
  switch (type) {
    case "default":
      return <div className={`preview-item default ${className}`}>
        <div className="label">{label}</div>
        <Value isSecret={isSecret} value={value}>
          {value ? value : <span className="empty">&nbsp;</span>}
        </Value>
      </div>;
    case "html":
      return <div className={`preview-item html ${className}`}>
        <div className="label">{label}</div>
        <div className="value" dangerouslySetInnerHTML={{__html: value || ''}}/>
      </div>;
    case "email":
      return <div className={`preview-item email ${className}`}>
        <div className="label">{label}</div>
        <Value isSecret={isSecret} value={value}>
          {value ? <a href={`mailto:${value}`}>{value}</a> : <span className="empty">&nbsp;</span>}
        </Value>
      </div>;
    case "link":
      return <div className={`preview-item link ${className}`}>
        <div className="label">{label}</div>
        <Value isSecret={isSecret} value={value}>
          {value ? <a href={value} target="_blank" rel="noopener noreferrer">{value}</a> : <span className="empty">&nbsp;</span>}
        </Value>
      </div>;
    case "image":
      return <div className={`preview-item image ${className}`}>
        <div className="label">{label}</div>
        <div className="value">
          <ImageTooltip src={value.src} title={value.title}/>
        </div>
      </div>;
    case "date":
      const date_: any = value ? new Date(value) : '';
      const value_ = isValid(date_) ? format(date_, dateFormat) : '';
      return <div className={`preview-item date ${className}`}>
        <div className="label">{label}</div>
        <Value isSecret={isSecret} value={value}>
          {value_ ? value_ : <span className="empty">&nbsp;</span>}
        </Value>
      </div>;
    case "main":
      let checkbox_ = (value.active) ? <CheckCircleOutlineIcon color="primary"/> :
        <RadioButtonUncheckedIcon color="primary"/>;
      return <div className={`preview-item main ${className}`}>
        <div className="value">{value.value}</div>
        {checkbox_}
      </div>;
    case "checkbox":
      return <div className={`preview-item checkbox ${className}`}>
        {(value) && <CheckCircleOutlineIcon color="primary"/>}
        {!(value) && <RadioButtonUncheckedIcon color="primary"/>}
        <div className="label">{label}</div>
      </div>;
    case "source":
      return <PreviewSource value={value} label={label}/>;
    default:
      return null;
  }
});

export default Preview;
