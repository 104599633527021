import {FC, createContext, useContext, ReactElement, useMemo, useEffect} from "react";
import source, {getKey} from "./source";
import {useAuth} from "./auth";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {setLanguage} from "../store/modules/language";

export const I18nContext = createContext({data: [], map: {}, loading: false});
export const useI18n = () => {
  const labels = useContext(I18nContext);
  return {
    ts(key: string) {
      if (labels.loading) return ' ';
      // @ts-ignore
      const translate = labels.map[key];
      if (!translate) {
        console.warn('label', key, 'not found');
        return (<i style={{color: '#f00'}}>{key}</i>);
      }
      return translate.title;
    },
    t(key: string) {
      if (labels.loading) return ' ';
      // @ts-ignore
      const translate = labels.map[key];
      if (!translate) {
        console.warn('label', key, 'not found');
        return key;
      }
      return translate.title;
    },
    loading: labels.loading,
    labels: labels.data,
    labelsMap: labels.map
  }
};

const createPath = (api: string, languageID: string | number, apiLabelsFilter: string) => {
  const prefix_ = typeof languageID === 'string' ? '"' : '';
  return `${api}&Filter=languageID==${prefix_}${languageID}${prefix_}${apiLabelsFilter ? ` %26%26 ${apiLabelsFilter}` : ''}`;
};

export const I18nContextProvider: FC<{
  apiLabels?: string;
  apiLabelsFilter?: string;
  aliLabelsID?: string;
  apiLanguages?: string;
  apiLanguagesID?: string;
  children: ReactElement;
}> = (
  {
    apiLabels = 'Labels/GetAllDynamic?Select=labelKey,title',
    apiLabelsFilter = '',
    aliLabelsID = 'labelKey',
    apiLanguages = 'Languages/GetAllDynamic?Select=id,title,direction&Filter=isActive==true&OrderBy=rank',
    apiLanguagesID = 'id',
    children
  }) => {
  const dispatch = useDispatch();
  const auth: any = useAuth();
  const default_ = useMemo(() => ({loading: true, data: [], map: {}}), []);
  // languages
  const languages = source(apiLanguages, apiLanguagesID, true);
  const language = useSelector((state: any) => state.language, shallowEqual);
  const path = useMemo(() => {
    return language.languageID ? createPath(apiLabels, language.languageID, apiLabelsFilter) : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language.languageID]);
  // labels
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const labelsKey = useMemo(() => getKey(apiLabels), []);
  const labels = useSelector((state: any) => {
    if (path) {
      const state_ = state.sources[labelsKey];
      return state_ ? state_[path] || default_ : default_;
    } else {
      return default_
    }
  }, shallowEqual);

  useEffect(() => {
    if (path) dispatch({type: 'SOURCE_INIT', key: labelsKey, path, fieldID: aliLabelsID, mapping: true});
    return () => {
      if (path) dispatch({type: 'SOURCE_MOUNT_UNMOUNT', key: labelsKey, path, methodType: -1});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  useEffect(() => {
    if (!language.languageID && auth.init && !languages.loading) {
      let item = languages.data[0];
      if (auth.user && auth.user.languageID) {
        item = languages.data.filter((item: any) => item[apiLanguagesID] === auth.user.languageID)[0] || languages.data[0];
        dispatch(setLanguage(auth.user.languageID, item, item.direction));
      } else {
        dispatch(setLanguage(item[apiLanguagesID], item, item.direction));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, languages, language]);

  return (
    <I18nContext.Provider value={{...labels}}>
      {children}
    </I18nContext.Provider>
  );
};
