export enum PERMISSIONS {
  IS_ALLOW_TO_EDIT_COMPANIES = "isAllowToEditCompanies",
  IS_ALLOW_TO_EDIT_METHODOLOGIES = "isAllowToEditMethodologies",
  IS_ALLOW_TO_EDIT_PERMISSION = "isAllowToEditPermission",
  IS_ALLOW_TO_EDIT_CONTENT = "isAllowToEditContent",
  IS_ALLOW_TO_EDIT_MARKETING = "isAllowToEditMarketing",
  IS_ALLOW_TO_EDIT_SETTING = "isAllowToEditSetting",
}

export enum TEAM_CATEGORY_OLD_ID {
  OUR = 3,
  DIRECTORS = 1,
}

export enum COMMON_PAGE_TYPE_ID {
  PUBLIC_INFORMATION = 7,
  JOBS_LAYOUT = 10,
  RATINGS_LAYOUT = 11,
  REPORTS_LAYOUT = 12,
}
