import {memo, useCallback, useMemo} from "react";
import {IconButton} from "@material-ui/core";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

export const Swap = memo((
  {
    onTasks,
    pagination,
    rowData,
    index
  }: {
    onTasks: (tasks: any[]) => void;
    pagination: any;
    rowData: any;
    index: number;
  }
) => {
  const disableUp = useMemo(() => {
    return pagination.page === 1 && index === 0
  }, [pagination.page, index]);
  const disableDown = useMemo(() => {
    return (pagination.page - 1) * pagination.size + index + 1 === pagination.total_;
  }, [pagination, index]);
  const onClick = useCallback((direction: boolean) => {
    onTasks([['swapData', {
      data: rowData,
      take: (pagination.page - 1) * pagination.size + index + 1 + ((direction) ? -1 : 1)
    }]])
  }, [pagination, index, rowData, onTasks]);
  return <td
    className="data-grid-col-controls swap"
  >
    <div>
      <IconButton
        aria-label="up"
        className="btn-up"
        tabIndex={-1}
        disabled={disableUp}
        onClick={() => onClick(true)}
      >
        <KeyboardArrowUpIcon fontSize="inherit"/>
      </IconButton>
      <IconButton
        aria-label="down"
        className="btn-down"
        tabIndex={-1}
        disabled={disableDown}
        onClick={() => onClick(false)}
      >
        <KeyboardArrowDownIcon fontSize="inherit"/>
      </IconButton>
    </div>
  </td>;
});

export default Swap;
