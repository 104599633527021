import React, {
  createRef,
  forwardRef,
  memo,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import useRouter, { IRoute } from '../router';
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useAuth } from '../auth';
import { NativeScroll } from 'components/native-scroll';

export const checkActive = (pathname: string, path: string): boolean => {
  // eslint-disable-next-line
  return path === pathname || (path.length > 1 && new RegExp(`${path}(\/|$)`, 'g').test(pathname));
};
export const MenuItem = memo(
  forwardRef(({ route, pathname, collapsed, level, goTo, onOpen }: any, ref: Ref<any>) => {
    const auth = useAuth();
    const children = useMemo(() => {
      if (route.routes) {
        return route.routes.filter(
          (route: IRoute) => route.inMenu && auth.permission(route.permission),
        );
      } else {
        return [];
      }
      // eslint-disable-next-line
    }, [route]);

    const [active, setActive] = useState(checkActive(pathname, route.path));
    const [open, setOpen] = useState(checkActive(pathname, route.path));
    const onClick = useCallback(() => {
      if (route.component) {
        if (onOpen) onOpen(true, ref);
        goTo(route.path);
      } else {
        const open_ = !open;
        if (onOpen) onOpen(open_, ref);
        setOpen(open_);
      }
      // eslint-disable-next-line
    }, [route, setOpen, onOpen, open]);
    const iconCollapsed = useMemo(() => {
      if (children.length) {
        return !collapsed && open ? (
          <ExpandLess className="icon-collapsed" />
        ) : (
          <ExpandMore className="icon-collapsed" />
        );
      } else {
        return null;
      }
    }, [children, open, collapsed]);
    const iconRoute = useMemo(() => {
      if (route.icon) {
        if (typeof route.icon === 'string') {
          return (
            <ListItemIcon className="icon-wrapper">
              <span className={`route-icon ${route.icon}`} />
            </ListItemIcon>
          );
        } else {
          return <ListItemIcon className="icon-wrapper">{route.icon.type.render()}</ListItemIcon>;
        }
      } else {
        return null;
      }
    }, [route]);
    useEffect(() => {
      setActive(checkActive(pathname, route.path));
    }, [route.path, setActive, pathname]);

    useImperativeHandle(ref, () => ({ setOpen }));

    if (!auth.permission(route.permission) || (route.routes && !children.length)) return null;
    return (
      <>
        <ListItem button onClick={onClick} className={`level-${level} ${active ? 'active' : ''}`}>
          {iconRoute}
          <ListItemText
            className="title"
            color="primary"
            primary={route.title}
            secondary={children.map((item: IRoute) => item.title).join(', ')}
          />
          {iconCollapsed}
        </ListItem>
        {children.length > 0 && (
          <Collapse in={!collapsed && open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {children.map((item: IRoute) => (
                <MenuItem
                  key={item.path}
                  route={item}
                  pathname={pathname}
                  collapsed={collapsed}
                  level={level + 1}
                  goTo={goTo}
                />
              ))}
            </List>
          </Collapse>
        )}
      </>
    );
  }),
);
export const MainNav = memo(({ collapsed = false, accordion = false }: any) => {
  const router = useRouter();
  const routes = useSelector((state) => (state as any).routes.routes);
  const goTo = useCallback((path: string) => {
    router.push({ pathname: path });
    // eslint-disable-next-line
  }, []);
  const [elRefs, setElRefs] = React.useState([]);
  const [menu, setMenu] = useState<any>(null);
  const onOpen = useCallback(
    (state: boolean, ref: any) => {
      if (state && accordion) {
        elRefs.forEach((item: any) => {
          if (ref !== item) item?.current?.setOpen(false);
        });
      }
      // some
    },
    [accordion, elRefs],
  );
  // init
  useEffect(() => {
    if (routes && routes.length) {
      const menu_ = routes.filter((route: IRoute) => route.inMenu);
      setElRefs(menu_.map(() => createRef()));
      setMenu(menu_);
    }
  }, [routes]);
  // render
  if (!menu) return null;
  return (
    <NativeScroll>
      <nav className={`main-nav ${collapsed ? 'collapsed' : ''}`}>
        <List component="div" disablePadding>
          {menu.map((route: IRoute, i: number) => (
            <MenuItem
              key={route.path}
              ref={elRefs[i]}
              route={route}
              pathname={router.pathname}
              collapsed={collapsed}
              onOpen={onOpen}
              level={1}
              goTo={goTo}
            />
          ))}
        </List>
      </nav>
    </NativeScroll>
  );
});
export default MainNav;
