export const prepareRequest = <T extends { [x: string]: any | null } = {}>(
  data: T,
) => {
  const keys = Object.keys(data) as (keyof T)[]
  return keys.reduce((acc, key) => {
    // @ts-ignore
    acc[key] = data[key] === "" ? null : data[key]
    return acc
  }, data)
}
export const normalizeDataBeforeSave = <T extends Record<string, any>>(
  data: T,
) => {
  return Object.keys(data).reduce((acc, key) => {
    const value = data[key]
    // @ts-ignore
    acc[key] = String(key).endsWith("ID") && value === "" ? null : value
    return acc
  }, {} as T)
}
