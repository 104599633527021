import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import source from '../source';
import { Autocomplete } from '@material-ui/lab';
import { CircularProgress, TextField } from '@material-ui/core';
import {
  api,
  apiStatic,
  checkEs6AndRun,
  checkES6Template,
  requestError,
  translateBySource,
} from '../helpers';
import { debounce, merge } from 'lodash';
import { useI18n } from '../i18';
import { useDispatch } from 'react-redux';
import { notifyRequestResult } from '../../store/modules/notify';

export interface ISelectSettings {
  label?: string;
  placeholder?: string;
  clearable?: boolean;
  multiple?: boolean;
  translateLabel?: boolean;
  translateDataSource?: any;
  source:
    | null
    | string
    | any[]
    | {
        url: string;
        filter: string; // es6 template
        // example:
        //    'someFiled == ${data} || someFiled.contains("${data}")
        //    where, ${data} === selectValue
        take: number;
        select?: string;
        static?: boolean;
      };
  class?: string;
  style?: any;
  option?: {
    label?: string;
    value?: string;
  };
}

export interface ISelect {
  settings: ISelectSettings;
  error?: any;
  value?: null | string | number | any[];
  onChange?: (e: any, value: any) => void;
}

export const settings = (data: any): ISelectSettings => ({
  label: data.label !== undefined ? data.label : '',
  placeholder: data.placeholder !== undefined ? data.placeholder : 'choose',
  clearable: data.clearable !== undefined ? data.clearable : true,
  multiple: data.multiple !== undefined ? data.multiple : false,
  translateLabel: data.translateLabel !== undefined ? data.translateLabel : false,
  translateDataSource: data.translateDataSource || false,
  source: data.source !== undefined ? data.source : '',
  class: data.class !== undefined ? data.class : '',
  style: data.style !== undefined ? data.style : '',
  option:
    data.option !== undefined
      ? data.option
      : {
          label: 'title',
          value: 'id',
        },
});
// STANDARD
export const calcValue = (value: any, source: any[], field: string, multiple: boolean) => {
  const result = value
    ? Array.isArray(value)
      ? source.filter(
          (item: any) => value.filter((val: string | number) => item[field] === val).length,
        )
      : source.filter((item: any) => item[field] === value)[0] || []
    : [];
  if (multiple) return result;
  return Array.isArray(result) && !result.length ? null : result;
};
export const calcValueTarget = (e: any, value: any, field: string) => {
  return {
    ...e,
    target: {
      ...e.target,
      value: value
        ? Array.isArray(value)
          ? value.length
            ? value.map((item) => item[field])
            : ''
          : value[field] || ''
        : '',
    },
  };
};
const SelectDefault = memo(
  ({ settings, error, value, onChange, source, disabled, renderCustom, ...rest }: any) => {
    const { t, labels } = useI18n();
    const [selectValue, setSelectValue] = useState<any>(settings.multiple ? [] : null);
    const onChangeSelect = useCallback(
      (e, value) => {
        setSelectValue(value);
        onChange(calcValueTarget(e, value, settings.option.value), value);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [onChange],
    );

    const Source = useMemo(() => {
      if (source) {
        if (settings.translateLabel) {
          const field_ = settings.option.label;
          return source.map((item: any) => {
            let translatedValue = '';

            if (settings.translateDataSource) {
              translatedValue = translateBySource({
                map: settings.translateDataSource.map,
                loading: settings.translateDataSource.loading,
                key: item[field_],
              });
            } else {
              translatedValue = t(item[field_]);
            }
            return { ...item, [field_]: translatedValue };
          });
        } else {
          return source;
        }
      } else {
        return [];
      }
      // eslint-disable-next-line
    }, [source, labels, settings.translateLabel, settings.option, settings.translateDataSource]);

    useEffect(() => {
      setSelectValue(calcValue(value, Source, settings.option.value, settings.multiple));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, Source]);
    const labelTemplate = useMemo(() => {
      if (typeof settings.option.label === 'function') {
        return settings.option.label;
      } else if (checkES6Template(settings.option.label)) {
        // eslint-disable-next-line
        return new Function('data', `return \`${settings.option.label}\``);
      } else {
        return null;
      }
    }, [settings.option.label]);
    return (
      <Autocomplete
        {...rest}
        multiple={rest.multiple || settings.multiple}
        disableClearable={!settings.clearable || rest.disableClearable}
        options={Source}
        disabled={rest.loading || disabled}
        onChange={onChangeSelect}
        value={selectValue}
        getOptionLabel={(item: any) =>
          labelTemplate ? labelTemplate(item) : `${item[settings.option.label]}`
        }
        getOptionSelected={(item: any, value: any) =>
          item[settings.option.value] === value[settings.option.value]
        }
        renderInput={(params) => {
          if (renderCustom) {
            return renderCustom({ selected: selectValue, error, loading: rest.loading, params });
          } else {
            return (
              <TextField
                {...params}
                fullWidth
                placeholder={t(settings.placeholder)}
                label={rest.label || t(settings.label)}
                style={settings.style || {}}
                error={Boolean(error)}
                helperText={error ? t(error.message) || '' : ''}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {rest.loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            );
          }
        }}
      />
    );
  },
);
const WrapperInnerSource: FC<any> = memo((props) => {
  return (
    <SelectDefault {...props} source={props.settings.source} loading={props.loading || false} />
  );
});
const WrapperRemoteSource: FC<any> = memo((props) => {
  const { data, loading } = source(props.settings.source);
  return <SelectDefault {...props} source={data} loading={loading || props.loading} />;
});
const WrapperRemoteSourceStatic: FC<any> = memo((props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (!props.disabled) {
      if (props.settings.source.url) {
        setLoading(true);
        apiStatic
          .get(props.settings.source.url)
          .then((response) => {
            setData(response.data.value);
            setLoading(false);
          })
          .catch((error) => {
            dispatch(notifyRequestResult(requestError(error), 'error'));
            console.error(error);
            setData([]);
            setLoading(false);
          });
      } else {
        console.error('please set correct settings Select', props);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setData, setLoading, props.settings.source.url, props.disabled]);
  return <SelectDefault {...props} source={data} loading={loading || props.loading} />;
});
// AUTOCOMPLETE
const createGetProps = (settings: any, value: string) => {
  const { url, take, select, filter } = settings;
  const props: any = {
    url,
    params: {
      Take: take,
      Count: true,
    },
  };
  if (select) props.params['Select'] = select;
  if (value) props.params['Filter'] = checkEs6AndRun(filter, value);
  return props;
};
const SelectAutocomplete: FC<any> = memo(
  ({ settings, error, value, onChange, loading, translateDataSource, disabled, ...rest }: any) => {
    const { t, labels } = useI18n();
    const [loading_, setLoading_] = useState(true);
    const [count, setCount] = useState(0);
    const [open, setOpen] = useState(false);
    const [selectOptions, setSelectOptions] = useState([]);
    const getOptions = useCallback(
      (inputValue: string) => {
        const props = createGetProps(settings.source, inputValue);
        setLoading_(true);
        api
          .request(props)
          .then((response) => {
            if (settings.translateLabel) {
              const field_ = settings.option.label;
              setSelectOptions(
                response.data.value.map((item: any) => {
                  let translatedValue = t(item[field_]);

                  if (translateDataSource) {
                    translatedValue = translateBySource({
                      map: translateDataSource.map,
                      loading: translateDataSource.loading,
                      key: item[field_],
                    });
                  }

                  return { ...item, [field_]: translatedValue };
                }),
              );
            } else {
              setSelectOptions(response.data.value);
            }
            setCount(response.data.count);
            setLoading_(false);
          })
          .catch((error) => {
            setLoading_(false);
            console.log(error);
          });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [settings, labels],
    );
    // eslint-disable-next-line
    const getOptionsDebounce = React.useCallback(debounce(getOptions, 400), []);
    const onChangeSelect = useCallback(
      (e, value) => {
        onChange(calcValueTarget(e, value, settings.option.value), value);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [onChange],
    );
    const labelTemplate = useMemo(() => {
      if (checkES6Template(settings.option.label)) {
        // eslint-disable-next-line
        return new Function('data', `return \`${settings.option.label}\``);
      } else {
        return null;
      }
    }, [settings.option.label]);
    useEffect(() => {
      getOptions('');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
      <Autocomplete
        {...rest}
        multiple={settings.multiple}
        disableClearable={!settings.clearable || rest.disableClearable}
        options={selectOptions}
        loading={loading_ || loading}
        disabled={loading_ || loading || disabled}
        onChange={onChangeSelect}
        getOptionLabel={(item: any) =>
          labelTemplate ? labelTemplate(item) : `${item[settings.option.label]}`
        }
        getOptionSelected={(item: any, value: any) =>
          item[settings.option.value] === value[settings.option.value]
        }
        onInputChange={(_, value) => getOptionsDebounce(value)}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={`${t(settings.placeholder)} ${
              open && count > settings.source.take
                ? `(show ${settings.source.take} items from ${count})`
                : ''
            }`}
            label={rest.label || t(settings.label)}
            style={settings.style || {}}
            error={Boolean(error)}
            helperText={error ? t(error.message) || '' : ''}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading_ || loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    );
  },
);
// @ts-ignore
export const Select = memo((props: any & ISelect) => {
  const settings_: ISelectSettings = useMemo(() => {
    return merge({}, settings(props), props.settings || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.settings, props.source, props.translateDataSource]);
  if (typeof settings_.source === 'string' && settings_.source.length) {
    // remote
    return <WrapperRemoteSource {...props} settings={settings_} />;
  } else if (Array.isArray(settings_.source)) {
    // inner
    return <WrapperInnerSource {...props} settings={settings_} />;
    // @ts-ignore
  } else if (settings_.source?.static) {
    // static
    return <WrapperRemoteSourceStatic {...props} settings={settings_} />;
    // @ts-ignore
  } else if (settings_.source?.url && settings_.source?.filter && settings_.source?.take) {
    // dynamic
    return <SelectAutocomplete {...props} settings={settings_} />;
  }
  return <i style={{ color: '#f00' }}>Create select error</i>;
});

export default Select;
