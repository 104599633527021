import {useDispatch, useSelector, shallowEqual} from "react-redux";
import {useEffect, useMemo} from "react";

export const getKey = (path: string) => path.slice(0, path.indexOf('/'));
export const Source = (path: string, fieldID = 'id', mapping = false) => {
  const dispatch = useDispatch();
  const default_ = useMemo(() => ({loading: true, data: [], map: {}}), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const key = useMemo(() => getKey(path), []);
  useEffect(() => {
      dispatch({type: 'SOURCE_INIT', key, path, fieldID, mapping});
      return () => {
        dispatch({type: 'SOURCE_MOUNT_UNMOUNT', key, path, methodType: -1});
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return useSelector(state => {
    const state_ = (state as any).sources;
    return state_
      ? state_[key]
        ? (state_)[key][path] || default_
        : default_
      : default_;
  }, shallowEqual);
};

export default Source;
