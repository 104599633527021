import {FC, memo, useEffect} from "react";

const EditorCheckbox: FC<{
  data: string,
  onClose: any,
  onSubmit: any
}> = ({data, onClose, onSubmit}) => {
  useEffect(() => {
    onSubmit({data: !Boolean(data)});
    onClose();
    // eslint-disable-next-line
  }, []);
  return null
};

export default memo(EditorCheckbox)
