import { FC, memo, ReactNode, useCallback, useMemo } from 'react';
import { IDataGridMadel } from '../model';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { useI18n } from '../../i18';

const Head: FC<{
  model: IDataGridMadel;
  sortable: any;
  onTasks: any;
  rowControls?: ReactNode;
  preview?: boolean;
}> = ({ model, onTasks, sortable, rowControls, preview }) => {
  const { t } = useI18n();
  const onClick = useCallback((column) => {
    if (column.sortable)
      onTasks([['sortableTrigger', column.field], ['pagination', { page: 1 }], ['getData']]);
    // eslint-disable-next-line
  }, []);
  const isSwap = useMemo(
    () => model && !preview && model.store.swap && model.store.swap.field,
    [preview, model],
  );
  const isRowControls = useMemo(
    () => model && !preview && ((model.controls && model.controls.length > 0) || rowControls),
    [preview, model, rowControls],
  );
  if (!model) return null;
  return (
    <>
      <colgroup>
        {isSwap && <col />}
        {model.columns.map((column) => (
          <col key={column.key} width={column.width} />
        ))}
        {isRowControls && <col />}
      </colgroup>
      <thead>
        <tr>
          {isSwap && (
            <th
              className="data-grid-col-controls swap"
              style={{
                zIndex: 8,
              }}
            >
              {/*// @ts-ignore*/}
              {t(model.store.swap.title)}
            </th>
          )}

          {model.columns.map((column) => (
            <th
              key={column.key}
              className={`${column.thClasses}${column.sortable ? ' sortable' : ''}${column.field === sortable.field ? ' active' : ''}`}
              onClick={() => onClick(column)}
              style={{ ...column.thStyles }}
            >
              {t(column.title)}
              {column.field === sortable.field && sortable.order === 'desc' && (
                <ArrowDropDownIcon />
              )}
              {column.field === sortable.field && sortable.order === 'asc' && <ArrowDropUpIcon />}
            </th>
          ))}
          {isRowControls && (
            <th className="data-grid-col-controls" style={{ zIndex: 8 }}>
              &nbsp;
            </th>
          )}
        </tr>
      </thead>
    </>
  );
};

export default memo(Head);
