// stores
import auth from "AurionCR/store/modules/auth"
import notify from "AurionCR/store/modules/notify"
import routes from "AurionCR/store/modules/routes"
import sources from "AurionCR/store/modules/sources"
import language from "AurionCR/store/modules/language"
// sagas
import authSaga from "AurionCR/store/sagas/auth"
import sourceSaga from "AurionCR/store/sagas/source"

export default {
  store: { auth, notify, routes, sources, language },
  sagas: [...authSaga, ...sourceSaga],
}
