import type { FC } from 'react';
// style
import theme from 'styles/theme';
import 'AurionCR/styles/_main.scss';
import 'styles/_project-theme.scss';
// store
import { Provider } from 'react-redux';
import store from 'AurionCR/store';
import storeConfig from 'store/redux';
// layout
import Page404 from 'AurionCR/pages/Page404';
import Layout from 'pages/Layout';
// router
import routesConfig from 'configs/routes';
// notify
import { SnackbarProvider } from 'notistack';
// l18n
import {
  AuthContextProvider,
  I18nContextProvider,
  LoadingOverlayProvider,
  Notifier,
  Routes,
  RTLThemeProvider,
} from 'AurionCR/components';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { AxiosInterceptors } from 'contexts/axios-interceptors';
import { AppConfigProvider } from 'contexts/app-config';
import { ErrorBoundary } from './components/error-boundary';

const App: FC = () => {
  window.authCookieName_ = 'midroog_auth_token_cms';
  window.authRefreshCookieName_ = 'midroog_auth_token_cms_refresh';
  window.authDateCookieName_ = 'midroog_auth_token_cms_exp';

  return (
    <ErrorBoundary>
      <AppConfigProvider>
        <Provider store={store(storeConfig)}>
          <AxiosInterceptors>
            <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
              <Notifier />
              <AuthContextProvider>
                <I18nContextProvider apiLabelsFilter='labelSource.key == "cms"'>
                  <RTLThemeProvider theme={theme}>
                    <LoadingOverlayProvider>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Routes layout={Layout} routes={routesConfig} page404={Page404} />
                      </MuiPickersUtilsProvider>
                    </LoadingOverlayProvider>
                  </RTLThemeProvider>
                </I18nContextProvider>
              </AuthContextProvider>
            </SnackbarProvider>
          </AxiosInterceptors>
        </Provider>
      </AppConfigProvider>
    </ErrorBoundary>
  );
};

export default App;
